import { render, staticRenderFns } from "./purchasing.vue?vue&type=template&id=38e4ce8c&scoped=true&"
import script from "./purchasing.vue?vue&type=script&lang=js&"
export * from "./purchasing.vue?vue&type=script&lang=js&"
import style0 from "./purchasing.vue?vue&type=style&index=0&id=38e4ce8c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e4ce8c",
  null
  
)

export default component.exports